import * as THREE from 'three';
import { extend } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';

const WaveMaterial = shaderMaterial(
  {
    time: 0,
    resolution: new THREE.Vector2(),
    pointer: new THREE.Vector2(),
    color1: new THREE.Color(0.0, 1.0, 1.0),
    color2: new THREE.Color(1.0, 0.0, 1.0),
    color3: new THREE.Color(1.0, 1.0, 0.0)
  },
  /*glsl*/ `
      varying vec2 vUv;
      void main() {
        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        vec4 viewPosition = viewMatrix * modelPosition;
        vec4 projectionPosition = projectionMatrix * viewPosition;
        gl_Position = projectionPosition;
        vUv = uv;
      }`,
  /*glsl*/ `
      uniform float time;
      uniform vec2 resolution;
      uniform vec2 pointer;
      varying vec2 vUv;

        uniform vec3 color1;
        uniform vec3 color2;
        uniform vec3 color3;

      // Simplex Noise
      vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
      vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
      vec3 permute(vec3 x) { return mod289((x * 34.0 + 1.0) * x); }

      float snoise(vec2 v) {
        const vec4 C = vec4(0.211324865405187, 0.366025403784439, -0.577350269189626, 0.024390243902439);
        vec2 i  = floor(v + dot(v, C.yy));
        vec2 x0 = v - i + dot(i, C.xx);
        vec2 i1;
        i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
        vec4 x12 = x0.xyxy + C.xxzz;
        x12.xy -= i1;
        i = mod289(i);
        vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0)) + i.x + vec3(0.0, i1.x, 1.0));
        vec3 m = max(0.5 - vec3(dot(x0, x0), dot(x12.xy, x12.xy), dot(x12.zw, x12.zw)), 0.0);
        m = m * m;
        m = m * m;
        vec3 x = 2.0 * fract(p * C.www) - 1.0;
        vec3 h = abs(x) - 0.5;
        vec3 ox = floor(x + 0.5);
        vec3 a0 = x - ox;
        m *= 1.79284291400159 - 0.85373472095314 * (a0 * a0 + h * h);
        vec3 g;
        g.x = a0.x * x0.x + h.x * x0.y;
        g.yz = a0.yz * x12.xz + h.yz * x12.yw;
        return 130.0 * dot(m, g);
      }

      void main() {
        vec2 uv = vUv * 2.0 - 1.0; // Transform vUv to range from -1 to 1
        uv *= 0.5; // Zoom out for better noise visibility

        // Time-based offsets for animation
        vec2 offset1 = vec2(time * 0.1, time * 0.1);
        vec2 offset2 = vec2(-time * 0.15, time * 0.15);

        // Generate noise values
        float noise1 = snoise(uv + offset1);
        float noise2 = snoise(uv + offset2);

        // Blend colors based on noise values
        vec3 color1 = color1; // Light blue
        vec3 color2 = color2; // Light green
        vec3 color3 = color3;

        vec3 color = mix(color1, color2, noise1 * 0.5 + 0.5);
        color = mix(color, color3, noise2 * 0.5 + 0.5);

        gl_FragColor = vec4(color, 1.0);
      }`
);

extend({ WaveMaterial });

export { WaveMaterial };
