import { Disclosure } from '@headlessui/react';
import { ChevronUp } from 'lucide-react';

type FaqProps = {
  questions: { q: string; a: string }[];
};

export default function Example({ questions }: FaqProps) {
  return (
    <div className="w-full pt-4">
      <div className="mx-auto w-full max-w-3xl rounded-2xl   flex flex-col gap-y-2">
        {questions.map((q, i) => (
          <Disclosure key={`msg-faq-${q.q}`}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg panel-bg px-2 py-2 text-left text-sm font-medium text-primary hover:bg-line focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 bg-neutral-100 dark:bg-neutral-900">
                  <span>{q.q}</span>
                  <ChevronUp
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-primary`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm ">
                  {q.a}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}
