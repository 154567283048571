type FeatureGridProps = {
  features: { title: string; description?: string; icon: any }[];
};

export default function FeatureGrid({ features }: FeatureGridProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-5 flex-wrap ">
      {features.map((feature) => (
        <div
          key={`ft-${feature.title}`}
          className=" max-w-xs bg-white dark:bg-black p-5 rounded-xl"
        >
          <div className="w-10 h-10  rounded-lg mb-1 flex items-center justify-center text-primary ">
            <feature.icon
              strokeWidth={2}
              // size={20}
              className="text-neutral-900 dark:text-neutral-200"
            />
          </div>
          <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
          {feature?.description && (
            <p className=" text-sm">{feature.description}</p>
          )}
        </div>
      ))}
    </div>
  );
}
