import LandingLayout from '@/components/LandingLayout';
import FooterCta from '@/components/ui/PageBlocks/Landing/FooterCta';
import ChatBenefits from '@/components/ui/PageBlocks/Landing/Home/ChatBenefits';
import SalesHero from '@/components/ui/PageBlocks/Landing/Home/SalesHero';
import SocialProof from '@/components/ui/PageBlocks/Landing/Home/SocialProof';
// import SocialProof from '@/components/ui/PageBlocks/Landing/Home/SocialProof';
import TechBenefits from '@/components/ui/PageBlocks/Landing/Home/TechBenefits';
import ResearchFaq from '@/components/ui/PageBlocks/Marketing/ResearchFaq';
import { getActiveProductsWithPrices } from '@/utils/supabase-client';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { ReactElement, useEffect, useRef } from 'react';
import { Gradient } from 'stripe-gradient';

const GlobeComponent = dynamic(
  () => import('@/components/ui/PageBlocks/Landing/Globe'), // replace with the path to your Globe component
  { ssr: false } // This line is important. It tells Next.js to only render the component on the client side
);

export const getStaticProps = async () => {
  const products = await getActiveProductsWithPrices();

  return {
    props: {
      products: products
    }
  };
};
export function New({ products }) {
  const planeDevRef = useRef(null);
  useEffect(() => {
    new Gradient({
      canvas: '#my-canvas-id',
      colors: ['#a960ee', '#ff333d', '#90e0ff', '#ffcb57']
    });
  }, []);
  return (
    <div className="relative min-h-screen top-0 landing-page-wrap  bg-landing-neutral pb-20 ">
      <Head>
        <title>markets.sh </title>
      </Head>
      {/* <div className="absolute top-0 right-0 overflow-hidden   z-10 w-1/2  h-full"></div> */}

      <SalesHero />
      <ChatBenefits />
      <SocialProof />
      <TechBenefits />
      <ResearchFaq
        questions={[
          {
            q: 'Are you going to sell my info or bombard me with emails?',
            a: `Absolutely not! We prioritize your data privacy above all else. Some users even wish we'd reach out a bit more. Trust us; we despise spam and cluttered inboxes just as much as you do.`
          },
          {
            q: 'Do you support data that is not listed here?',
            a: `You bet! Just drop us an email or get in touch via discord. We have a lot of unreleased data and technology`
          },
          {
            q: 'Do you offer refunds? ',
            a: `You can try out all endpoints in the free plan. We will offer no-questions asked refund in the first 30 days.`
          },
          {
            q: 'Do you offer an SDK? ',
            a: `Not right now, we are currently adding many new endpoints. Once everything is a bit more stable, we are planning to release Python and Javascript libraries.`
          }
        ]}
      />
      <div className="mb-20" />
      <FooterCta />
    </div>
  );
}

New.getMainLayout = (page: ReactElement) => {
  // Todo: Make the header a nice gradient backdrop with the color of the stock?
  return <LandingLayout>{page}</LandingLayout>;
};

export default New;
