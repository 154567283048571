import { useRef, useEffect, Suspense, useState } from 'react';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import {
  useGLTF,
  Caustics,
  Environment,
  MeshRefractionMaterial
} from '@react-three/drei';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { RGBELoader } from 'three-stdlib';
import { useTheme } from 'next-themes';
import { Flex, Box } from '@react-three/flex';
import { Box3, Color, Vector3 } from 'three';
import { WaveMaterial } from './WaveMaterial'; // Import the WaveMaterial component
import useIsMobile from '@/hooks/useIsMobile';
const textureName = '/assets/workshop.hdr';
// 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr';

function ShaderPlane() {
  const ref = useRef();
  const { viewport, size } = useThree();
  const [color1, setColor1] = useState(new Color(1.0, 1.0, 1.0));
  const [color2, setColor2] = useState(new Color(1.0, 1.0, 1.0));
  const [color3, setColor3] = useState(new Color(1.0, 1.0, 1.0));

  const { resolvedTheme } = useTheme();
  useEffect(() => {
    if (resolvedTheme === 'light') {
      setColor1(new Color(0.95, 1.0, 1.0));
      setColor2(new Color(0.7, 0.9, 0.9));
      setColor3(new Color(1.0, 0.9, 0.9));
    } else {
      setColor1(new Color(0.0, 0.0, 0.0));
      setColor2(new Color(0.0, 0.02, 0.03));
      setColor3(new Color(0.0, 0.0, 0.0));
    }
  }, [resolvedTheme]);

  // ...

  return (
    <mesh scale={[viewport.width, viewport.height, 1]}>
      <planeGeometry />
      <waveMaterial // Add the WaveMaterial component
        ref={ref}
        key={WaveMaterial.key}
        color1={color1} // White
        color2={color2} // Light grey
        color3={color3} // Darker grey
        resolution={[size.width, size.height]}
      />
    </mesh>
  );
}
function Diamond(props) {
  const ref = useRef<THREE.Mesh>();
  const { nodes } = useGLTF('/assets/dflat.glb');
  const texture = useLoader(RGBELoader, textureName);
  const { resolvedTheme } = useTheme();
  const color = resolvedTheme === 'light' ? '#9f8f8f' : '#222';

  // const config = useControls({
  //   bounces: { value: 3, min: 0, max: 8, step: 1 },
  //   aberrationStrength: { value: 0.1, min: 0, max: 0.4, step: 0.01 },
  //   ior: { value: 3, min: 0, max: 10 },
  //   fresnel: { value: 0.1, min: 0, max: 1 },
  //   color: color
  // });

  const config = {
    bounces: 4,
    aberrationStrength: 0.15,
    ior: 3,
    fresnel: 0.4,
    color: color
  };

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     const { clientX, clientY } = event;
  //     const x = (clientX / window.innerWidth) * 2 - 1;
  //     const y = -(clientY / window.innerHeight) * 2 + 1;

  //     ref.current.rotation.x = y * Math.PI;
  //     ref.current.rotation.y = x * Math.PI;
  //   };

  //   window.addEventListener('mousemove', handleMouseMove);
  //   return () => window.removeEventListener('mousemove', handleMouseMove);
  // }, []);
  console.log('props.width:', props.width);
  const [modelWith, setModelWidth] = useState(1);
  const [scale, setScale] = useState(1);
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (nodes && nodes.Diamond_1_0 && (nodes as any).Diamond_1_0.geometry) {
      // const geometry = nodes.Diamond_1_0.geometry;
      const boundingBox = new Box3().setFromObject(ref.current);
      const size = new Vector3();
      boundingBox.getSize(size);
      setModelWidth(size.x);
      console.log('Model size:', size);
    }
  }, [nodes]);

  useEffect(() => {
    setScale(props.width / modelWith);
  }, [props.width, modelWith]);

  // Add rotation to the diamond
  useFrame((state, delta) => {
    if (!ref.current) return;
    ref.current.rotation.y += 0.001; // Adjust rotation speed as needed
    ref.current.rotation.x = 0.4;
  });

  return (
    // <CubeCamera resolution={32} frames={1} envMap={texture}>
    //   {(texture) => (
    <Caustics
      causticsOnly={false}
      backside={false}
      color={config.color}
      position={[0, 0, 0]}
      worldRadius={3}
      ior={1.8}
      intensity={0.1}
    >
      <mesh
        castShadow
        ref={ref}
        // scale={[props.width, 1, 1]} // Set the x scale to props.width
        scale={[scale, scale, scale]}
        geometry={(nodes as any).Diamond_1_0.geometry}
        {...props}
      >
        <MeshRefractionMaterial
          envMap={texture}
          {...config}
          toneMapped={false}
        />
      </mesh>
    </Caustics>
    //   )}
    // </CubeCamera>
  );
}

// function Background() {
//   const { viewport, size } = useThree();
//   return (
//     <Flex
//       // padding={30}
//       // margin={5}
//       // dir="column"
//       position={[-viewport.width / 2, viewport.height / 2, -1]}
//       size={[viewport.width, viewport.height, 0]}

//       // onReflow={handleReflow}
//     >
//       <Box
//         centerAnchor
//         dir="row"
//         align={'center'}
//         justify="flex-end"
//         width="100%"
//         height="100%"
//         minHeight="100%"
//       >
//         {(width, height) => <ShaderPlane />}
//       </Box>
//     </Flex>
//   );
// }

function Content() {
  const isMobile = useIsMobile();
  const { viewport, size } = useThree();

  useEffect(() => {
    console.log('Viewport width:', viewport.width);
    console.log('Viewport height:', viewport.height);
  }, [viewport]);
  return (
    <Flex
      dir={isMobile ? 'column-reverse' : 'row'}
      position={[-viewport.width / 2, viewport.height / 2, 0]}
      size={[viewport.width, viewport.height, 0]}
      padding={viewport.width / 10}
      // alignItems={'center'}
      // onReflow={handleReflow}
    >
      <Box width="auto" height="auto" flexGrow={1} centerAnchor>
        {(width, height) => (
          <mesh visible={false}>
            <planeGeometry args={[width, height]} />
            <meshBasicMaterial color={'transparent'} opacity={0} />
          </mesh>
        )}
      </Box>
      <Box width="auto" height="auto" flexGrow={1} centerAnchor>
        {(width, height) => <Diamond width={width} />}
      </Box>
    </Flex>
  );
}
{
}
{
  /* <Plane args={[990, 990]}  /> */
}

export default function App() {
  const { resolvedTheme } = useTheme();
  return (
    <Canvas
      shadows
      // camera={{ position: [1, 0.5, 5], fov: 45 }}
      camera={{ position: [0, 0, 5], far: 10, fov: 45 }}
      gl={{
        powerPreference: 'high-performance',
        alpha: false,
        antialias: false,
        stencil: false,
        depth: false
      }}
      dpr={1}
      className=""
      style={{ height: '100vh', width: '100vw' }}
    >
      <color
        attach="background"
        args={[resolvedTheme == 'light' ? '#' : '#0a0a0a']}
      />
      <ambientLight intensity={0.5 * Math.PI} />
      <Suspense fallback={null}>
        <Content />
        {/* <Background /> */}
      </Suspense>

      <Environment files={textureName} />
      {/* <OrbitControls
        makeDefault
        autoRotate
        autoRotateSpeed={0.1}
        minPolarAngle={1}
        maxPolarAngle={Math.PI / 2}
      /> */}
      {/* <ShaderPlane /> */}
      {/* <MovingSpot /> */}
      <EffectComposer>
        <Bloom luminanceThreshold={1.2} intensity={1.5} levels={5} mipmapBlur />
      </EffectComposer>
    </Canvas>
  );
}
