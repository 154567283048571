import {
  DownloadCloud,
  Newspaper,
  Bell,
  Github,
  Slack,
  Lock
} from 'lucide-react';
import ChatBenefitsGrid from '../ChatBenefitsGrid';

const EmpowerIcon = ({ className }) => {
  return (
    <svg
      width="245"
      height="245"
      className={className}
      viewBox="0 0 245 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_53_455)">
        <rect
          x="1"
          y="1"
          width="243"
          height="243"
          rx="14"
          className="fill-landing-neutral stroke-line"
          // fill="black"
          strokeWidth="2"
        />
        <rect
          x="1"
          y="1"
          width="243"
          height="243"
          rx="14"
          stroke="url(#paint0_radial_53_455)"
          strokeWidth="2"
        />
        <g filter="url(#filter0_f_53_455)">
          <path
            d="M55.45 202L123 85L190.55 202H55.45Z"
            stroke="#FFD600"
            strokeWidth="4"
          />
        </g>
        <g filter="url(#filter1_d_53_455)">
          <path
            d="M55.45 202L123 85L190.55 202H55.45Z"
            stroke="#FFD600"
            strokeWidth="4"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_f_53_455)">
          <path
            d="M55.45 176L123 59L190.55 176H55.45Z"
            stroke="#01B5DD"
            strokeWidth="4"
          />
        </g>
        <g filter="url(#filter3_d_53_455)">
          <path
            d="M55.45 176L123 59L190.55 176H55.45Z"
            stroke="#01B5DD"
            strokeWidth="4"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter4_f_53_455)">
          <path
            d="M55.45 151L123 34L190.55 151H55.45Z"
            stroke="#01DD3D"
            strokeWidth="4"
          />
        </g>
        <g filter="url(#filter5_d_53_455)">
          <path
            d="M55.45 151L123 34L190.55 151H55.45Z"
            stroke="#01DD3D"
            strokeWidth="4"
            shapeRendering="crispEdges"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_53_455"
          x="33.5858"
          y="62.6"
          width="178.828"
          height="159.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="9.2"
            result="effect1_foregroundBlur_53_455"
          />
        </filter>
        <filter
          id="filter1_d_53_455"
          x="47.9858"
          y="81"
          width="150.028"
          height="131"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_53_455"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_f_53_455"
          x="33.5858"
          y="36.6"
          width="178.828"
          height="159.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="9.2"
            result="effect1_foregroundBlur_53_455"
          />
        </filter>
        <filter
          id="filter3_d_53_455"
          x="47.9858"
          y="55"
          width="150.028"
          height="131"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_53_455"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_f_53_455"
          x="33.5858"
          y="11.6"
          width="178.828"
          height="159.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="9.2"
            result="effect1_foregroundBlur_53_455"
          />
        </filter>
        <filter
          id="filter5_d_53_455"
          x="47.9858"
          y="30"
          width="150.028"
          height="131"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_53_455"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_53_455"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(123.28 -2.11141e-05) rotate(90) scale(177.118)"
        >
          <stop stopColor="#E5E5E5" />
          <stop offset="1" stopColor="#3B3B3B" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_53_455">
          <rect width="245" height="245" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default function Features() {
  return (
    <div className="flex flex-col  landing-page pt-20 ">
      <div className="flex z-10 relative flex-col items-center  gap-y-10  pb-20 px-5 ">
        <div className="flex flex-col items-center landing-page   px-5 ">
          <h2 className="text-4xl font-semibold text-center max-w-lg tracking-tighter leading-tight mb-5">
            Automation That Makes
            <br /> A Difference
          </h2>
          <p className="text-center   md:text-md">
            We use autonomous AI to automate the repetitive, time intensive and
            boring parts of the sales pipeline. We enable sales teams to spend
            less time researching, and close more deals.
          </p>
        </div>
        <ChatBenefitsGrid
          features={[
            {
              title: 'Open Data',
              description: `We strongly believe that your data belongs to you and it should be accessible to you at all times. With comprehensive API support, CSV and Excel download`,
              icon: DownloadCloud
            },
            {
              title: 'News Feed And Events',
              description: `We are tracking events, news, price changes, and announcements with AI automatically. This allows us to identify what is truly important and notify you.`,
              icon: Newspaper
            },
            {
              title: 'Alerts And Notifications',
              description: `Set up alerts and notifications for your portfolio and stocks. We will notify you when something important happens`,
              icon: Bell
            },
            {
              title: 'Powerful API',
              description: `We strongly believe that your data belongs to you and it should be accessible to you at all times. With comprehensive API support, CSV and Excel download`,
              icon: Github
            },
            {
              title: 'Third Party Integrations',
              description: `Receive notifications and alerts on your favorite platforms like Slack or WhatsApp. Pull data into your favorite tools like Google Sheets, Excel, and more.`,
              icon: Slack
            },
            {
              title: 'High Security',
              description: `SOC II compliant, encrypted and constantly monitored. We take security very seriously and we are constantly improving our security practices.`,
              icon: Lock
            }
          ]}
        />
      </div>
    </div>
  );
}
